import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import Icon from 'koba/components/Icon';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { useRouter } from 'next/router';
import { useCommunityContext } from 'components/CommunityContext';
import { goToSpaces } from 'utils/routerHelpers';
import { getIconNameByStoredValue } from 'helpers/spaceHelpers';
import { rawId } from 'utils/rawId';

import styles from './PostSpaceBadge.module.scss';

const cx = classNames.bind(styles);

interface PostSpaceBadge {
  communityId: string;
  spaceId: string;
  spaceName: string;
  spaceIconName: string;
}

const PostSpaceBadge: React.FC<PostSpaceBadge> = ({
  communityId,
  spaceId,
  spaceName,
  spaceIconName,
}) => {
  const { t } = useTranslation();
  const router = useRouter();
  const { community, trackEvent } = useCommunityContext();

  const handleClickOnSpaceBadge = (event, filter, name) => {
    event.preventDefault();
    event.stopPropagation();

    trackEvent(TrackingEvent.SPACE_CLICKED, {
      [TrackingProperty.SPACE_ID]: rawId(filter),
      [TrackingProperty.SPACE_NAME]: name,
    });

    goToSpaces(router, filter, community.id, true);
  };

  return (
    <a
      aria-label={t('Go to {{space}}', {
        space: spaceName,
      })}
      className={cx('badge')}
      data-qa="space-badge-link"
      href={`/communities/${communityId}/spaces/${spaceId}`}
      title={spaceName || ''}
      onClick={(event) => handleClickOnSpaceBadge(event, spaceId, spaceName)}
    >
      <Icon
        className={cx('badge__icon')}
        data-qa="post__space__icon"
        name={getIconNameByStoredValue(spaceIconName || '')}
      />
      {spaceName}
    </a>
  );
};

export default PostSpaceBadge;

import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { PostListUser } from 'types/user';
import { PostAction } from 'types/post';
import { H1, Caption } from 'koba/components/Typography';
import Icon from 'koba/components/Icon';
import PostActions from 'components/PostActions';
import UserDetail from 'components/UserDetail';

import styles from '../Post.module.scss';

const cx = classNames.bind(styles);

interface PostHeaderProps {
  isFollowing: boolean;
  author?: PostListUser | null;
  editedAt: string;
  creationDateTime: string;
  pinnedAt: string;
  postActionsList: PostAction[];
  showFollowingTag: boolean;
  isMobile: boolean;
  title: string | ReactNode;
}

const PostHeader: React.FC<PostHeaderProps> = ({
  isFollowing,
  author,
  editedAt,
  creationDateTime,
  pinnedAt,
  showFollowingTag,
  postActionsList,
  isMobile,
  title,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={cx('post__detail-header', {
          'is-following': showFollowingTag && isFollowing,
        })}
      >
        <UserDetail
          author={author}
          editedAt={editedAt}
          time={creationDateTime || ''}
        />
        <div className={cx('post__detail-actions')}>
          {showFollowingTag && isFollowing && (
            <Caption className={cx('post__detail-actions__follow-tag')}>
              {t('Following')}
            </Caption>
          )}
          {pinnedAt && (
            <Icon
              ariaLabel={t('This post is pinned to the top of the list')}
              className={cx('post__detail-actions__pin-icon')}
              name="pin"
            />
          )}
          {postActionsList.length > 0 && (
            <PostActions
              actionsList={postActionsList}
              isMobile={isMobile}
              t={t}
            />
          )}
        </div>
      </div>
      <H1 className={cx('post__title')} data-qa="main-post-title__text">
        {title}
      </H1>
    </>
  );
};

export default PostHeader;

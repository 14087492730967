import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from '../Modal.module.scss';

const cx = classNames.bind(styles);

const ModalBody = ({ children, isFullWidth, hasTitle, ...rest }) => (
  <div
    className={cx('modal__body', {
      'no-padding': isFullWidth,
      'no-title': !hasTitle,
    })}
    {...rest}
  >
    {children}
  </div>
);

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  hasTitle: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

ModalBody.defaultProps = {
  isFullWidth: false,
  hasTitle: false,
};

ModalBody.displayName = 'ModalBody';

export default ModalBody;

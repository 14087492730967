import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import { Tooltip } from '@thinkific/toga-react';
import { useTranslation } from 'react-i18next';

import { MENTION_REGEX } from 'utils/regex';
import { eventNames } from 'constants/eventNames';
import TrackingEvent, { TrackingProperty } from 'constants/trackingEvents';
import { useCommunityContext } from 'components/CommunityContext';
import UserAvatar from 'components/UserAvatar';
import { IdMap } from '../HighlightContainer/interfaces';

import styles from './Highlight.module.scss';

const cx = classNames.bind(styles);

interface HighlightProps {
  className: string;
  emailAndLinkMap: {
    [key: string]: string;
  };
  highlightMatch: string;
  userIdToAvatarMap: IdMap;
}

export const Highlight: React.FC<HighlightProps> = ({
  highlightMatch,
  className: mentionClassName,
  emailAndLinkMap,
  userIdToAvatarMap,
}) => {
  const { trackEvent, currentUser } = useCommunityContext();
  const { t } = useTranslation();
  const userMentionRef = useRef(null);
  const isMention = highlightMatch.match(MENTION_REGEX);
  const isLink = !!emailAndLinkMap[highlightMatch];

  const name =
    (isMention &&
      `${highlightMatch.substring(
        highlightMatch.indexOf('[') + 1,
        highlightMatch.lastIndexOf(']')
      )}`) ||
    (t('User not found') as string);

  const userId =
    (isMention &&
      `${highlightMatch.substring(
        highlightMatch.indexOf('(') + 1,
        highlightMatch.lastIndexOf(')')
      )}`) ||
    '';

  const onKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleOpenUserProfilePanel(event);
    }
  };

  const avatarUrl = userIdToAvatarMap?.[userId];

  const handleOpenUserProfilePanel = (event) => {
    event.preventDefault();
    event.stopPropagation();
    PubSub.publish(eventNames.OPEN_PROFILE_PANEL, {
      userId,
      lastElementRef: userMentionRef,
    });

    trackEvent(TrackingEvent.PROFILE_PANEL_TAB_OPENED, {
      [TrackingProperty.WAS_OPENED_FROM_MENTION]: true,
    });
  };

  if (isMention) {
    return (
      <Tooltip
        className={cx('mention__tooltip')}
        displayElements={
          <UserAvatar
            avatarUrl={avatarUrl}
            className={cx('user__avatar')}
            size="xs"
            srcSet={`${avatarUrl}?width=40 1x, ${avatarUrl}?width=40&dpr=2 2x, ${avatarUrl}?width=40&dpr=3 3x`}
            userId={userId || ''}
            username={name}
          />
        }
        displayElementsClassName={cx('mention__tooltip-display')}
        innerClassName={cx('mention__tooltip-inner')}
        key={name}
        placement="top"
        title={
          <span
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </span>
        }
        isDark
        isOpaque
      >
        <span
          aria-hidden={false}
          aria-label={name}
          className={cx(mentionClassName.trim(), {
            'current-user': currentUser.id === userId,
          })}
          data-qa="mentions__highlight"
          ref={userMentionRef}
          role="button"
          tabIndex={0}
          onClick={handleOpenUserProfilePanel}
          onKeyDown={onKeyDown}
        >
          @{name}
        </span>
      </Tooltip>
    );
  }

  if (isLink) {
    return (
      <a
        data-qa="link__highlight"
        href={emailAndLinkMap[highlightMatch]}
        target="_blank"
        onClick={(event) => event.stopPropagation()}
      >
        {highlightMatch}
      </a>
    );
  }

  return <span className={cx('highlight')}>{highlightMatch}</span>;
};

export default Highlight;

import { MentionedUsersFieldFragment as MentionedUsers } from '__generated__/graphql/legacy/graphql';
import { mentionIdRegex, MENTION_REGEX_GLOBAL } from '../regex';
import { insertAtPosition } from '../textHelpers';

export const markupPattern = '@[__display__](__id__)';
export const displayTransform = (_id: string, display: string): string => {
  return `@${display}`;
};

/*
  Update user names in a block of text with their current name
 */
const replaceMentions = (
  text?: string | null,
  mentionedUsers?: MentionedUsers | null
): string => {
  let result = text || '';
  mentionedUsers?.edges?.forEach((edge) => {
    const node = edge?.node;
    if (node?.id && node?.fullName) {
      result = result.replace(mentionIdRegex(node.id), () => {
        return `@[${node.fullName}](${node.id})`;
      });
    }
  });
  return result;
};

const makeMentionsUserFriendly = (text: string): string => {
  let result = text || '';

  if (result) {
    result = result.replace(MENTION_REGEX_GLOBAL, '@$1');
  }

  return result;
};

/*
  A super maintainable function that can insert emojis into react-mentions text. My sincerest apologies to the
  next person that needs to make a change to this.
  It requires that markupPattern equal @[__display__](__id__) and displayTransform returns @${display}
 */
const insertEmoji = (
  emoji: string,
  position: number | null,
  value: string,
  content: string
): string => {
  if (!emoji) return content || '';
  if (position === null || position > value?.length) return content;

  const currentContent = content;
  let isRestrictedPosition = false;

  let shiftRight = 0;
  const restrictedRanges: Array<[number, number]> = [];

  if (value !== content) {
    // If the textarea and the react-mentions content are different, it means mentions have been added
    for (let i = 0; i < position; i += 1) {
      const currentChar = value[i];
      const currentContentChar = currentContent[i + shiftRight];
      const nextContentChar = currentContent[i + shiftRight + 1];
      if (
        currentChar === '@' &&
        currentContentChar === '@' &&
        nextContentChar === '['
      ) {
        const indexOfNextClosingBracket = currentContent.indexOf(
          ']',
          shiftRight + i + 2
        );
        if (indexOfNextClosingBracket > -1) {
          const name = currentContent.substring(
            i + shiftRight + 2,
            indexOfNextClosingBracket
          );
          if (value.indexOf(name, i + 1) === i + 1) {
            // we know the next set of characters is the name portion of a mention
            if (currentContent[indexOfNextClosingBracket + 1] === '(') {
              // We are at the start of the id portion of a mention
              const indexOfFirstClosingBrace = indexOfNextClosingBracket + 1;
              const indexOfNextClosingBrace = currentContent.indexOf(
                ')',
                indexOfNextClosingBracket + 2
              );
              if (indexOfNextClosingBrace > -1) {
                const lengthOfId =
                  indexOfNextClosingBrace - indexOfFirstClosingBrace + 1; // +1 to include both parenthesis
                restrictedRanges.push([
                  i + shiftRight + 1,
                  indexOfNextClosingBrace,
                ]);
                shiftRight += lengthOfId + 2; // +2 for the []
              }
            }
          }
        }
      }
    }
    const shiftedPosition = position + shiftRight;
    isRestrictedPosition = restrictedRanges.some(([start, end]) => {
      return shiftedPosition >= start && shiftedPosition <= end;
    });
  }

  if (!isRestrictedPosition) {
    return insertAtPosition(currentContent, position + shiftRight, emoji);
  }
  return content || '';
};

export { replaceMentions, insertEmoji, makeMentionsUserFriendly };

import React, { MouseEvent, KeyboardEvent } from 'react';
import classNames from 'classnames/bind';

import styles from './ActionModalItem.module.scss';

const cx = classNames.bind(styles);

interface ActionModalItemProps {
  children: React.ReactNode;
  isDanger?: boolean;
  title: string | React.ReactNode;
  onClick: (e: MouseEvent | KeyboardEvent) => void;
}

const ActionModalItem: React.FC<ActionModalItemProps> = ({
  children,
  isDanger = false,
  title,
  onClick,
}) => (
  <div
    className={cx('action-modal-item', {
      'action-modal-item--danger': isDanger,
    })}
  >
    <div
      className={cx('action-modal-item__button')}
      role="button"
      tabIndex={0}
      title={title as string} // Apparently we sometimes pass a react node here which doesn't match up
      onClick={onClick}
      onKeyPress={onClick}
    >
      {children}
    </div>
  </div>
);

export default ActionModalItem;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  DROPDOWN_DIMENSIONS_PROP_TYPE,
  DROPDOWN_DIMENSIONS_DEFAULT_PROP,
} from 'koba/components/Dropdown/constants';

import { DANGER_TYPE } from './constants';
import Button from '../Button';
import Icon from '../Icon';
import { Dropdown, Menu, Item } from '../Dropdown';

import styles from './InlineActions.module.scss';

const cx = classNames.bind(styles);

class InlineActions extends Component {
  constructor(props) {
    super(props);
    this.onHoverHandler = this.onHoverHandler.bind(this);
    this.onMenuItemClick = this.onMenuItemClick.bind(this);
    this.onToggleHandler = this.onToggleHandler.bind(this);
    this.state = {
      hasFocus: props.hasFocus,
      isOpen: false,
    };
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { hasFocus } = nextProps;
    this.setState({ hasFocus });
  }

  onHoverHandler = (hasFocus) => (e) => {
    e.stopPropagation();
    this.setState({ hasFocus });
  };

  onMenuItemClick = (onClickHandler) => (e) => {
    e.stopPropagation();
    this.setState({ isOpen: false });
    onClickHandler();
  };

  onToggleHandler = (open) => {
    this.setState({ isOpen: open });
  };

  getMainAction = () => {
    const { actions } = this.props;
    return actions.filter((action) => action.asButton);
  };

  renderActions() {
    const { buttonAppearance, forceMainActionOpen, ariaLabel } = this.props;
    const { hasFocus, isOpen } = this.state;
    const actionClasses = cx('action-button', {
      active: forceMainActionOpen || hasFocus || isOpen,
    });
    return this.getMainAction().map((action) => (
      <Button
        appearance={buttonAppearance}
        ariaLabel={ariaLabel}
        className={actionClasses}
        key={action.name}
        onBlur={this.onHoverHandler(false)}
        onClick={action.onClick}
        onFocus={this.onHoverHandler(true)}
        onMouseOut={this.onHoverHandler(false)}
        onMouseOver={this.onHoverHandler(true)}
      >
        {action.name}
      </Button>
    ));
  }

  renderMoreActions() {
    const {
      ariaLabel,
      buttonAppearance,
      icon,
      actions,
      alignment,
      forceMainActionOpen,
      isCompactMode,
      dimensions,
    } = this.props;
    const { hasFocus, isOpen } = this.state;
    const isActionActive = forceMainActionOpen || hasFocus || isOpen;
    const deleteActions = actions.filter(
      (action) => action.type === DANGER_TYPE
    );
    const nonSpecializedActions = actions.filter(
      (action) => !action.asButton && action.type !== DANGER_TYPE
    );
    const hasMainAction = this.getMainAction().length > 0;

    return (
      <div className={cx('ellipsis-button')}>
        <Dropdown toggleListener={this.onToggleHandler}>
          {({ open, toggleMenu }) => (
            <>
              <Button
                appearance={buttonAppearance}
                ariaLabel={ariaLabel}
                className={cx('inline-actions--toggle', {
                  'inline-actions--active-toggle': isActionActive,
                  'button--default': isActionActive,
                  'compact-mode': isCompactMode,
                  'has-main-action': !isCompactMode && hasMainAction,
                })}
                dataQA="inline-actions__btn"
                onClick={toggleMenu}
              >
                <Icon name={icon} />
              </Button>
              <Menu dimensions={dimensions} open={open} position={alignment}>
                {isCompactMode &&
                  this.getMainAction().map((action) => (
                    <Item
                      key={action.name}
                      title={action.title || action.text}
                      onClick={this.onMenuItemClick(action.onClick)}
                    >
                      <Button
                        appearance="knockout"
                        className={cx('inline-actions__more-actions--button')}
                      >
                        {action.text}
                      </Button>
                    </Item>
                  ))}
                {nonSpecializedActions.map((action) => (
                  <Item
                    className={cx('inline-actions__item')}
                    key={action.name}
                    title={action.title || action.text}
                    onClick={this.onMenuItemClick(action.onClick)}
                  >
                    <Button
                      appearance="knockout"
                      className={cx('inline-actions__more-actions--button')}
                    >
                      {action.text}
                    </Button>
                  </Item>
                ))}
                {deleteActions.length > 0 && (
                  <>
                    {nonSpecializedActions.length > 0 && (
                      <hr className={cx('inline-actions__separator')} />
                    )}
                    {deleteActions.map((deleteAction) => (
                      <Item
                        className={cx('inline-actions__item', 'dropdown')}
                        key={deleteAction.name}
                        title={deleteAction.title || deleteAction.text}
                        onClick={deleteAction.onClick}
                      >
                        <Button
                          appearance="knockout"
                          className={cx(
                            'inline-actions__more-actions--button',
                            'inline-actions__item--danger'
                          )}
                          hasDanger
                        >
                          {deleteAction.text}
                        </Button>
                      </Item>
                    ))}
                  </>
                )}
              </Menu>
            </>
          )}
        </Dropdown>
      </div>
    );
  }

  render() {
    const { alignment, isCompactMode, wrapperClass } = this.props;
    const containerClasses = cx(
      'container',
      `container-${alignment}`,
      wrapperClass
    );
    return (
      <div className={containerClasses}>
        {alignment === 'right' && !isCompactMode && this.renderActions()}
        {this.renderMoreActions()}
        {alignment === 'left' && !isCompactMode && this.renderActions()}
      </div>
    );
  }
}

InlineActions.propTypes = {
  /** Array of the possible actions */
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      /** Flag to show as button instead of within toggle menu */
      asButton: PropTypes.bool,
      /** Name of action (appears as button text) */
      name: PropTypes.string.isRequired,
      /** More detailed text to display for action (appears in action menu) */
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      /** Title to be displayed on action hover. Should be passed when text property is a node. */
      title: PropTypes.string,
      /** Handler when action is clicked */
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  /** Alignment of the menu and buttons with the toggle */
  alignment: PropTypes.oneOf(['left', 'right']),
  /** Button aria-label */
  ariaLabel: PropTypes.string,
  /** Button appearance */
  buttonAppearance: PropTypes.oneOf(['default', 'knockout', 'ghost']),
  dimensions: DROPDOWN_DIMENSIONS_PROP_TYPE,
  /** For the main action to be shown * */
  forceMainActionOpen: PropTypes.bool, // eslint-disable-line react/boolean-prop-naming
  /** Flag to indicate component is in focus */
  hasFocus: PropTypes.bool,
  /** Icon for menu toggle */
  icon: PropTypes.string,
  /** Toggles the compact version * */
  isCompactMode: PropTypes.bool,
  /** Add classes to the wrapper container * */
  wrapperClass: PropTypes.string,
};

InlineActions.defaultProps = {
  ariaLabel: null,
  buttonAppearance: 'default',
  hasFocus: false,
  icon: 'ellipsis-v',
  alignment: 'right',
  forceMainActionOpen: false,
  isCompactMode: false,
  wrapperClass: null,
  dimensions: DROPDOWN_DIMENSIONS_DEFAULT_PROP,
};

export default InlineActions;

import React, { useState, MouseEvent, KeyboardEvent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import InlineActions from 'koba/components/InlineActions';
import { DANGER_TYPE } from 'koba/components/InlineActions/constants';
import { ButtonWithIconOnly } from 'koba/components/Button';
import Icon from 'koba/components/Icon';
import { ActionModal, ActionModalItem } from 'components/ActionModal';

import { TFunction } from 'next-i18next';
import { PostAction } from 'types/post';

import styles from './PostActions.module.scss';

const cx = classNames.bind(styles);

interface PostActionsProps {
  actionsList?: PostAction[];
  isMobile?: boolean;
  t: TFunction;
}

const PostActions: React.FC<PostActionsProps> = ({
  actionsList = [],
  isMobile = false,
  t,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openActionsModal = (e: MouseEvent | KeyboardEvent) => {
    e?.stopPropagation();
    setIsOpen(true);
  };

  const closeActionsModal = (e: MouseEvent | KeyboardEvent) => {
    e?.stopPropagation();
    setIsOpen(false);
  };

  const handleAction = (action) => (e: MouseEvent | KeyboardEvent) => {
    setIsOpen(false);
    e?.stopPropagation();
    action();
  };

  return (
    <>
      {isMobile ? (
        <>
          <ButtonWithIconOnly
            appearance="knockout"
            ariaLabel={t('Open/close actions menu')}
            className={cx('post-actions__button')}
            onClick={openActionsModal}
          >
            <Icon
              className={cx('post-actions__button-icon')}
              name="ellipsis-v"
            />
          </ButtonWithIconOnly>
          <ActionModal closeModal={closeActionsModal} isOpen={isOpen} t={t}>
            {actionsList.map((action) => (
              <ActionModalItem
                isDanger={action.type === DANGER_TYPE}
                key={action.name}
                title={action.title || action.text}
                onClick={handleAction(action.onClick)}
              >
                {action.text}
              </ActionModalItem>
            ))}
          </ActionModal>
        </>
      ) : (
        <InlineActions
          actions={actionsList}
          ariaLabel={t('Open/close actions menu')}
          buttonAppearance="knockout"
          wrapperClass={cx('comment__inline-actions')}
        />
      )}
    </>
  );
};

PostActions.propTypes = {
  actionsList: PropTypes.array,
  isMobile: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

PostActions.defaultProps = {
  actionsList: [],
  isMobile: false,
};

export default PostActions;

import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames/bind';
import Button from '../Button';
import { H1 } from '../Typography';
import Icon from '../Icon';
import ModalBody from './ModalBody/ModalBody';
import ModalFooter from './ModalFooter/ModalFooter';
import ModalHeader from './ModalHeader/ModalHeader';
import styles from './Modal.module.scss';

const cx = classNames.bind(styles);

const findComponent = (children, componentToFind) =>
  React.Children.toArray(children).find(
    (component) =>
      component.type && component.type.displayName === componentToFind
  );

const Modal = ({
  modalBodyClasses,
  children,
  contentLabel,
  closeModal,
  isOpen,
  title,
  footer,
  isFullWidth,
  dataQA,
}) => (
  <ReactModal
    ariaHideApp={false}
    className={cx('modal', modalBodyClasses)}
    contentLabel={contentLabel}
    data={{
      qa: dataQA,
    }}
    isOpen={isOpen}
    overlayClassName={cx('overlay')}
    onRequestClose={closeModal}
  >
    {findComponent(children, 'ModalHeader')}
    {title && (
      <ModalHeader hasTitle={!!title}>
        <H1>{title}</H1>
        <Button
          appearance="knockout"
          aria-label="Close modal"
          className={cx('modal__header__close-button')}
          onClick={closeModal}
        >
          <Icon name="x-dismiss" />
        </Button>
      </ModalHeader>
    )}
    {findComponent(children, 'ModalBody') || (
      <ModalBody hasTitle={!!title} isFullWidth={isFullWidth}>
        {children}
      </ModalBody>
    )}

    {findComponent(children, 'ModalFooter')}
    {footer && <ModalFooter>{footer}</ModalFooter>}
  </ReactModal>
);

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
  contentLabel: PropTypes.string,
  dataQA: PropTypes.string,
  footer: PropTypes.node,
  isFullWidth: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  modalBodyClasses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  title: PropTypes.node,
};

Modal.defaultProps = {
  dataQA: null,
  modalBodyClasses: null,
  title: null,
  contentLabel: 'Modal',
  footer: null,
  isFullWidth: false,
};

export default Modal;

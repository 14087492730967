import React from 'react';
import dynamic from 'next/dynamic';
import classNames from 'classnames/bind';

import HighlightContainer from 'components/HighlightArea/HighlightContainer';
import { Loader, Button } from '@thinkific/toga-react';
import {
  MentionedUsersFieldFragment,
  PostAttachmentType,
} from '__generated__/graphql/legacy/graphql';

import { TFunction } from 'next-i18next';
import styles from '../Post.module.scss';

const cx = classNames.bind(styles);

interface PostContentProps {
  shouldClampContent: boolean;
  setShouldClampContent: (shouldClamp: boolean) => void;
  postToScrollToOffset?: number;
  content: string;
  hasAttachment: boolean;
  firstAttachment?: {
    attachmentType: PostAttachmentType | null;
    url: string | null;
    altText: string | null;
    fileName: string | null;
    fileSizeInBytes: number | null;
  };
  shouldScroll: boolean;
  mentionedUsers?: MentionedUsersFieldFragment | null;
  isPostPage: boolean;
  t: TFunction;
}

let AttachmentDisplayer;

const PostContent: React.FC<PostContentProps> = ({
  shouldClampContent,
  postToScrollToOffset,
  setShouldClampContent,
  content,
  hasAttachment,
  firstAttachment,
  mentionedUsers,
  isPostPage,
  shouldScroll,
  t,
}) => {
  // Component Loading
  if (!AttachmentDisplayer) {
    AttachmentDisplayer = dynamic(
      () => import('components/AttachmentDisplayer'),
      {
        loading: () => (
          <Loader accessibilityLabel={t('common-loading', 'Loading')} />
        ),
      }
    );
  }

  const handleExpandCollapse = (e: Event) => {
    if (!shouldClampContent && shouldScroll) {
      window.scrollTo(0, postToScrollToOffset || 0);
    }

    e.preventDefault();
    e.stopPropagation();
    setShouldClampContent(!shouldClampContent);
  };

  const readContentButton = content.length > 480 && (
    <Button
      appearance="accessible-link"
      className={cx('post__content--button')}
      data-qa="read-post__button"
      onClick={handleExpandCollapse}
    >
      {shouldClampContent ? t('Read more') : t('Read less')}
    </Button>
  );

  return (
    <span
      className={cx(
        'post__content',
        hasAttachment ? 'post__content--attachment' : ''
      )}
      data-qa="post__content"
    >
      <div
        className={cx(
          'post__content__text',
          shouldClampContent ? 'post__content__text--clamped' : ''
        )}
      >
        <HighlightContainer content={content} mentionedUsers={mentionedUsers} />
      </div>
      {readContentButton}
      {hasAttachment && (
        <AttachmentDisplayer
          attachment={firstAttachment}
          isPostPage={isPostPage}
          t={t}
        />
      )}
    </span>
  );
};

export default PostContent;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from '../Modal.module.scss';

const cx = classNames.bind(styles);

const ModalFooter = ({ children, ...rest }) => (
  <div className={cx('modal__footer')} {...rest}>
    {children}
  </div>
);

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

ModalFooter.displayName = 'ModalFooter';

export default ModalFooter;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from '../Modal.module.scss';

const cx = classNames.bind(styles);

const ModalHeader = ({ children, className, hasTitle, ...rest }) => (
  <div className={cx('modal__header', className)} {...rest}>
    <div className={cx({ 'has-title': hasTitle })}>{children}</div>
  </div>
);

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
  ]),
  hasTitle: PropTypes.bool,
};

ModalHeader.defaultProps = {
  className: null,
  hasTitle: false,
};

ModalHeader.displayName = 'ModalHeader';

export default ModalHeader;
